import React from "react";
import "../../style/css/style.css";
import UpperSection from "../common/UpperSection";
import BottomSection from "./BottomSection";
import { motion } from "framer-motion";

const HomeScreen: React.FC = () => {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%", transition: { duration: 0.1 } }}
    >
      <UpperSection shouldLocationDisplayed={true} />
      <BottomSection />
    </motion.div>
  );
};

export default HomeScreen;
