import React, { useState } from "react";
import "../../style/css/style.css";
import logo from "../../style/images/blue-logo-without-tagline.svg";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PersonalDetails: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const navigate = useNavigate();

  const handleConfirmationEvent = () => {
    navigate("/homepage");
  };
  return (
    <div className="parent-container bg-white flex-grow">
      <div className="flex flex-col items-center">
        <div className="mb-6 rounded-md pt-[127px]">
          <img
            src={logo}
            alt="urban naps"
            style={{ width: "139px", height: "73px" }}
          />
        </div>
        <div className="text-2xl mt-6 text-center text-customBlackReg font-bold px-12">
          Personal Details
        </div>
        <div className="mt-4 mb-6 border-b-2 border-customBlue rounded-md w-20"></div>
        <div className="text-[15px] text-center px-8 text-customGreyReg font-normal my-3">
          Hello, please add your first name and last name
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="relative w-[360px]">
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
            className="border border-customBorderLightGrey rounded-lg py-2 pl-10 pr-4 w-full text-[15px]"
          />
          <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-customEnvelopeColor" />
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="relative w-[360px]">
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
            className="border border-customBorderLightGrey rounded-lg py-2 pl-10 pr-4 w-full text-[15px]"
          />
          <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-customEnvelopeColor" />
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <button
          className={`confirm-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-[360px] text-sm ${"bg-customBlue"}`}
          onClick={handleConfirmationEvent}
        >
          DONE
        </button>
      </div>
    </div>
  );
};

export default PersonalDetails;
