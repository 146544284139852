import React, { useState, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import Loader from "./components/common/Loader";
import AnimatedRoutes from "./components/AnimatedRoutes/AnimatedRoutes";

const App: React.FC = () => (
  <BrowserRouter>
    <AppRouter />
  </BrowserRouter>
);

const AppRouter: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return isLoading ? <Loader /> : <AnimatedRoutes />;
};

export default App;
