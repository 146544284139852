import React from "react";
import UpperSection from "../common/UpperSection";
import BookingSection from "./BookingSection";

const MyBookings: React.FC = () => {
  return (
    <div>
      <UpperSection shouldLocationDisplayed={false} />
      <BookingSection />
    </div>
  );
};

export default MyBookings;
