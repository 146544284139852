import React from "react";
import "../../style/css/style.css";
import logo from "../../style/images/blue-logo-without-tagline.svg";
import { useNavigate } from "react-router-dom";

const CheckEmail: React.FC = () => {
  const navigate = useNavigate();

  const openEmailApp = () => {
    window.location.href = "mailto:";
  };

  const handleNextEvent = () => {
    navigate("/personal-details");
  };
  return (
    <div className="parent-container bg-white flex-grow">
      <div className="flex flex-col items-center">
        <div className="mb-6 rounded-md pt-[127px]">
          <img
            src={logo}
            alt="urban naps"
            style={{ width: "139px", height: "73px" }}
          />
        </div>
        <div className="text-2xl mt-6 text-center text-customBlackReg font-bold px-12">
          Check email
        </div>
        <div className="mt-4 mb-6 border-b-2 border-customBlue rounded-md w-20"></div>
        <div className="text-[15px] text-center px-12 text-customGreyReg font-normal my-3">
          Click on the email verification link sent to you on
          xxxx@gmail.com.Then click on next.
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button
          className={`confirm-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-[360px] text-sm ${"bg-customBlue"}`}
          onClick={openEmailApp}
        >
          OPEN EMAIL APP
        </button>
      </div>
      <div className="flex justify-center mt-4">
        <button
          className={`confirm-button hover:bg-blue-700 text-customBlue border-customBlue border font-bold py-2 px-4 rounded-lg w-[360px] text-sm ${"bg-white"}`}
          onClick={handleNextEvent}
        >
          NEXT
        </button>
      </div>
      <div className="flex justify-center mt-6">
        <div className="flex text-[15px] text-center px-12 font-normal my-3">
          <div className="text-customGreyReg">
            Didn't receive the email yet?
          </div>
          <div className="ms-1 font-bold underline text-customBlue">
            SEND AGAIN
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckEmail;
