import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../style/css/style.css";
import { postCheckoutPodPlanById } from "../../services/PodService";
import { toast } from "react-toastify";

type CheckOutProps = {
  onClose: () => void;
  setIsCheckoutCancel: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmCheckOut: () => void;
};

const CheckOut: React.FC<CheckOutProps> = ({
  onClose,
  setIsCheckoutCancel,
  onConfirmCheckOut,
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    setIsCheckoutCancel(true);
    onClose();
    navigate("/bookings");
  };

  const handleYesClick = async () => {
    try {
      const bookingDetails = localStorage.getItem("bookingDetails");

      if (bookingDetails) {
        const parsedDetails = JSON.parse(bookingDetails);

        const bookingId = parsedDetails?.response?.data?.booking_id;

        if (!bookingId) {
          return;
        }

        const apiResponse = await postCheckoutPodPlanById(bookingId);
        if (apiResponse.status.success) {
          onConfirmCheckOut();
          onClose();
          navigate("/rating");
        } else {
          toast.error(apiResponse.status.errorMessage);
        }
      } else {
        console.error("No booking details found in localStorage.");
        toast.error(
          "Booking details are missing. Cannot proceed with checkout."
        );
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("An error occurred while checking out. Please try again.");
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="time-card-container bg-white flex-grow">
        <div className="flex flex-col items-center">
          <div className="mt-4 mb-6 border-b-4 border-customBorderGrey rounded-md w-10"></div>
          <div className="text-lg text-customBlack font-semibold text-center">
            Are you sure you want to check-out from your pod?
          </div>
          <div className="text-sm text-customTextGrey font-normal pt-2">
            This action cannot be reverted
          </div>
        </div>

        <div className="flex justify-center py-8">
          <button
            className="confirm-button hover:bg-blue-700 text-customBlue font-bold py-2 px-4 rounded-[10px] w-[273px] text-sm bg-white mr-4 border border-customBlue h-10"
            onClick={handleBackClick}
          >
            GO BACK
          </button>
          <button
            className="confirm-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-[10px] w-[273px] text-sm bg-customBlue h-10"
            onClick={handleYesClick}
          >
            YES
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
