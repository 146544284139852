import { SetStateAction } from "react";
import StarRatings from "react-star-ratings";

interface StarRatingProps {
  rating: number;
  setRating: React.Dispatch<SetStateAction<number>>;
}

const COLOR_STAR_YELLOW = "rgb(251, 192, 45)";
const COLOR_EMPTY_STAR_YELLOW = "#E1E2E3";

const StarRating = ({ rating, setRating }: StarRatingProps) => {
  const changeRating = (newRating: number) => {
    setRating(newRating);
  };

  return (
    <StarRatings
      rating={rating}
      starRatedColor={COLOR_STAR_YELLOW}
      starEmptyColor={COLOR_EMPTY_STAR_YELLOW}
      starHoverColor={COLOR_STAR_YELLOW}
      starDimension="40px"
      starSpacing="2px"
      changeRating={changeRating}
      numberOfStars={5}
    />
  );
};

export default StarRating;
