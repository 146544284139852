export interface PodData {
  id: string;
  thingName: string;
  title: string;
  displayName: string;
  available: string;
  nextAvailableIn: string;
  is_active?: boolean;
}

export interface OrganizationData {
  is_active: boolean;
  is_payment_enabled: boolean;
  id: string;
  slug: string;
  title: string;
  type: string;
  website: string | null;
  address: string;
  logo_url: string | null;
}
export interface OrganizationDataAvailability {
  status: {
    success: boolean;
  };
  data: OrganizationData;
}
export interface PlanData {
  is_active: boolean;
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  plan_name: string;
  description: string | null;
  original_amount: number;
  discounted_amount: number;
  duration: number;
  organization_id: string;
}

export const RoomStatus = {
  AVAILABLE: "AVAILABLE",
  OCCUPIED: "OCCUPIED",
  UNDER_MAINTENANCE: "UNDER_MAINTENANCE",
  NO_POD_FOUND: "NO POD FOUND",
  UNAVAILABLE: "UNAVAILABLE",
};
