import logo from "../../style/images/blue-logo-without-tagline.svg";
import { useNavigate } from "react-router-dom";
import "../../style/css/style.css";
import { useEffect, useState } from "react";

const ThankYou: React.FC = () => {
  const navigate = useNavigate();

  const [bookingDetails, setBookingDetails] = useState<{
    response: any;
    planName: string;
    pod_id: string | null;
  } | null>(null);

  const handleNextClick = () => {
    navigate("/bookings");
  };
  useEffect(() => {
    const bookingDetails = sessionStorage.getItem("bookingDetails");
    if (bookingDetails) {
      const parsedDetails = JSON.parse(bookingDetails);
      setBookingDetails(parsedDetails);
    }
  }, []);
  if (bookingDetails) console.log(bookingDetails);
  return (
    <div className="flex-container bg-black">
      <div className="thank-you-container bg-white flex-grow p-[14px]">
        <div className="flex flex-col items-center">
          <div className="mb-6 rounded-md">
            <img
              src={logo}
              alt="urban naps"
              style={{ width: "139px", height: "73px" }}
            />
          </div>
          <div className="text-base text-center text-[#2A2A34] font-semibold -mt-2">
            Thank you for booking your{" "}
            {bookingDetails && bookingDetails?.planName} with Urban Naps.
          </div>
          <div className="text-sm text-[#9B9696] font-medium my-3">
            Have a great Nap!
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <button
            className={`confirm-button hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-[330px] text-sm bg-[#0E97E6]`}
            onClick={handleNextClick}
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
