import noPod from "../../style/images/no_pod.svg";

const NoPodFound = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={noPod} alt="no pod found" />
      <div className="text-lg mt-6 text-center text-customBlackReg font-bold px-12">
        Oops! We couldn't find the pod.
      </div>
      <div className="text-sm text-center px-12 text-customGreyReg font-normal my-2">
        Please contact the Admin.
      </div>
    </div>
  );
};

export default NoPodFound;
