import { Route, Routes, useLocation } from "react-router-dom";
import SplashScreen from "../SplashScreen/SplashScreen";
import HomeScreen from "../HomeScreen/HomeScreen";
import ThankYou from "../ThankYou/ThankYou";
import MyBookings from "../MyBookings/MyBookings";
import Rating from "../Rating/Rating";
import { AnimatePresence } from "framer-motion";
import Registration from "../Registration/Registration";
import CheckEmail from "../Registration/CheckEmail";
import PersonalDetails from "../Registration/PersonalDetails";
import { ToastContainer } from "react-toastify";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <ToastContainer />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/homepage" element={<HomeScreen />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/bookings" element={<MyBookings />} />
        <Route path="/rating" element={<Rating />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/check-email" element={<CheckEmail />} />
        <Route path="/personal-details" element={<PersonalDetails />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
