import React, { useState } from "react";
import pod from "../../style/images/pod.svg";
import { PodData, RoomStatus } from "../../interfaces/Pod.interface";
import ModalComponent from "../common/Modal";
import TimeCard from "../TimeSlot/TimeCard";

const InfoCard: React.FC<{ podData: PodData }> = ({ podData }) => {
  const { nextAvailableIn, title, available, id } = podData;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPodId, setSelectedPodId] = useState<string | null>(null);

  const handlePodClick = () => {
    setSelectedPodId(id);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedPodId(null);
  };

  const getStatusColor = (available: string): string => {
    switch (available) {
      case RoomStatus.AVAILABLE:
        return "#53A700";
      case RoomStatus.UNAVAILABLE:
        return "#BEBEBE";
      case RoomStatus.UNDER_MAINTENANCE:
        return "#BEBEBE";

      default:
        if (available.startsWith("NEXT AVAILABLE IN")) {
          return "#E7B22A";
        }
        return "#BEBEBE";
    }
  };
  return (
    <>
      <div
        className="card-parent flex max-w-sm p-6 bg-white border border-customBorderWhite rounded-[10px] shadow"
        onClick={available === "AVAILABLE" ? handlePodClick : undefined}
      >
        <img
          className="h-[56px] w-[65px] mr-4"
          src={podData?.thingName !== "no_pod_found" ? pod : pod}
          alt="pod"
        />
        <div>
          <p className="mb-2 font-semibold text-xl tracking-tight  text-2E2D2D">
            {title}
          </p>
          <p
            className="font-bold text-xs"
            style={{ color: getStatusColor(available) }}
          >
            {available === "OCCUPIED"
              ? `NEXT AVAILABLE IN ${nextAvailableIn} MINS`
              : available}
          </p>
        </div>
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Pod Info"
      >
        <TimeCard
          selectedPodId={selectedPodId}
          handleModalClose={handleModalClose}
        />
      </ModalComponent>
    </>
  );
};

export default InfoCard;
