import React, { ReactNode } from "react";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion";
import "../../style/css/style.css";

type ModalComponentProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  contentLabel?: string;
  children: ReactNode;
};

const customStyles: Modal.Styles = {
  overlay: {
    backgroundColor: "rgba(217, 223, 229, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  content: {
    padding: 0,
    position: "relative",
    border: "none",
    inset: "auto auto 0",
    background: "transparent",
  },
};

const ModalComponent: React.FC<ModalComponentProps> = ({
  isOpen,
  onRequestClose,
  children,
  contentLabel = "Modal",
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          style={customStyles}
          contentLabel={contentLabel}
          ariaHideApp={false}
        >
          <motion.div
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            style={{
              width: "90vw",
              maxWidth: "500px",
              margin: "0 auto",
              background: "white",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            {children}
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default ModalComponent;
