import React, { useState } from "react";
import "../../style/css/style.css";

interface FeedbackItem {
  title: string;
}

interface FeedbackCardProps {
  item: FeedbackItem;
  onSelect: () => void;
  selected: boolean;
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({
  item,
  onSelect,
  selected,
}) => {
  return (
    <div
      className={`feedback-card ${
        selected ? "selected" : ""
      } flex items-center justify-center w-full font-medium`}
      onClick={onSelect}
    >
      <h3
        className={`font-base text-base ${
          selected ? "font-bold" : ""
        } text-center`}
      >
        {item.title}
      </h3>
    </div>
  );
};

const FeedbackForm: React.FC = () => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleSelect = (title: string) => {
    const index = selectedItems.indexOf(title);
    if (index === -1) {
      setSelectedItems([...selectedItems, title]);
    } else {
      const updatedItems = [...selectedItems];
      updatedItems.splice(index, 1);
      setSelectedItems(updatedItems);
    }
  };

  const feedbackItems: FeedbackItem[] = [
    { title: "Cleanliness" },
    { title: "Comfortable" },
    { title: "Ease of use" },
    { title: "Safety" },
    { title: "Relaxation" },
  ];

  return (
    <div className="feedback-form mt-8 p-4">
      <div className="feedback-cards">
        <div className="grid grid-cols-2 gap-4 mb-4">
          {feedbackItems.slice(0, 2).map((item, index) => (
            <FeedbackCard
              key={index}
              item={item}
              onSelect={() => handleSelect(item.title)}
              selected={selectedItems.includes(item.title)}
            />
          ))}
        </div>

        <div className="grid grid-cols-3 gap-4">
          {feedbackItems.slice(2).map((item, index) => (
            <FeedbackCard
              key={index}
              item={item}
              onSelect={() => handleSelect(item.title)}
              selected={selectedItems.includes(item.title)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;
