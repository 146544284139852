import axios from "axios";
import {
  OrganizationData,
  OrganizationDataAvailability,
  PlanData,
  PodData,
} from "../interfaces/Pod.interface";
import { BASE_URL } from "../appConfig";

export const fetchPodDataByOrganization = async (
  bookingID: any
): Promise<PodData[]> => {
  try {
    const response = await axios.get<{ data: PodData[] }>(
      `${BASE_URL}/pod/organization/${bookingID}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchOrganizationBySlug = async (
  organizationName: string
): Promise<OrganizationData> => {
  try {
    const response = await axios.get<{ data: OrganizationData }>(
      `${BASE_URL}/organization/slug/${organizationName}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
};

export const fetchOrganizationBySlugAvailability = async (
  organizationName: string
): Promise<OrganizationDataAvailability> => {
  try {
    const response = await axios.get<{
      status: { success: boolean };
      data: OrganizationData;
    }>(`${BASE_URL}/organization/slug/${organizationName}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
};

export const fetchPlanDataByPod = async (
  selectedPodId: string | null | undefined
): Promise<PlanData[]> => {
  try {
    const response = await axios.get<{ data: PlanData[] }>(
      `${BASE_URL}/plan/pod/${selectedPodId}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const bookPodWithPlan = async (dataToSend: any): Promise<any> => {
  try {
    const response = await axios.post<any>(`${BASE_URL}/book`, dataToSend);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

//rename this API
export const fetchPodPlanById = async (podId: string): Promise<any> => {
  try {
    const response = await axios.get<any>(`${BASE_URL}/book/${podId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching pod plan data:", error);
    throw error;
  }
};

export const fetchRemoteControlPodPlanById = async (
  podId: string
): Promise<any> => {
  try {
    const response = await axios.get<any>(`${BASE_URL}/book/${podId}/remote`);
    return response.data;
  } catch (error) {
    console.error("Error fetching remote control pod plan data:", error);
    throw error;
  }
};

export const putRemoteControlPodPlanById = async (
  pod_id: string | null | undefined,
  booking_id: string | null,
  action: {
    light?: number;
    door?: number;
  }
): Promise<any> => {
  try {
    const response = await axios.put<any>(
      `${BASE_URL}/book/${booking_id}/remote`,
      {
        pod_id,
        action,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching remote control pod plan data:", error);
    throw error;
  }
};

export const postCheckoutPodPlanById =
  async (postDataToSend: {}): Promise<any> => {
    try {
      const response = await axios.post<any>(
        `${BASE_URL}/book/${postDataToSend}/checkout`
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error occurred during the checkout process. Unable to post checkout data for the pod plan. Please check the booking ID and network connectivity:",
        error
      );
      throw error;
    }
  };
