import React from "react";
import { MoonLoader } from "react-spinners";

interface LoaderProps {
  isCompact?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isCompact = false }) => (
  <div
    className={`flex items-center justify-center ${
      isCompact ? "h-[10vh]" : "h-[100vh]"
    }`}
  >
    <MoonLoader color="#3661d6" size={60} speedMultiplier={0.5} />
  </div>
);

export default Loader;
