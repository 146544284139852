export const convertToIST = (dateString: string) => {
  const date = new Date(dateString);

  // Convert to IST using toLocaleTimeString with the "Asia/Kolkata" timeZone option
  const istTime = date.toLocaleTimeString("en-IN", {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 24-hour format
  });

  return istTime;
};
