import React, { useEffect, useState } from "react";
import pin from "../../style/images/location.svg";
import { fetchOrganizationBySlug } from "../../services/PodService";
import { OrganizationData } from "../../interfaces/Pod.interface";

interface UpperSectionProps {
  shouldLocationDisplayed: boolean;
}

const UpperSection: React.FC<UpperSectionProps> = ({
  shouldLocationDisplayed,
}) => {
  const getCompanyNameFromURL = (): string => {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    return parts[0];
  };

  const organizationName = getCompanyNameFromURL();
  const [data, setData] = useState<OrganizationData>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const slug =
          organizationName === "localhost" ? "urbannaps" : organizationName;
        //commented code temporarily to prevent default selection of urbannaps
        // const slug = organizationName;
        const responseData = await fetchOrganizationBySlug(slug);
        setData(responseData);
      } catch (error) {
        console.error("Failed to fetch organization data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="bg-customLightBlue text-white px-1 h-[229px] pt-24 rounded-b-[2.5rem] ps-3">
        <div className="custom-font animate-pulse bg-gray-300 h-6 w-36 mb-2 ms-4"></div>
        <div className="custom-font animate-pulse bg-gray-300 h-8 w-48 mb-4 ms-4"></div>
        {shouldLocationDisplayed ? (
          <div className="flex ps-4 pt-2">
            <div className="animate-pulse bg-gray-300 h-5 w-5 rounded-full me-2"></div>
            <div className="location-font animate-pulse bg-gray-300 h-6 w-48 ps-2"></div>
          </div>
        ) : (
          <div className="location-font animate-pulse bg-gray-300 h-6 w-48 ps-2"></div>
        )}
      </div>
    );
  }

  return (
    <div className="bg-customLightBlue text-white px-1 h-[229px] pt-24 rounded-b-[2.5rem] ps-3">
      <div className="custom-font">{data?.title && "Welcome to"}</div>
      <div className="custom-font">{data?.title}</div>
      {shouldLocationDisplayed ? (
        <div className="flex ps-4 pt-2">
          {data?.title && <img src={pin} alt="location" />}
          <div className="location-font ps-2">{data?.address}</div>
        </div>
      ) : (
        <div className="location-font ps-2">Thank you for booking</div>
      )}
    </div>
  );
};

export default UpperSection;
